import { NotificationType } from '@local/web-design-system';
import { Dispatch } from 'redux';
import store from 'store';
import { API_REGION } from 'state-domains/constants';
import { loadSubscription } from 'state-domains/domain/subscription/state/actions';
import { typeComplete, typeFail, typePending } from 'state-domains/utils';
import { UserPortalState } from 'src/state';

import {
    ADD_SNACKBAR_NOTIFICATION,
    LOAD_ACCOUNT_PREFERENCES,
    LOAD_CURRENT_USER,
    LOAD_RECENT_PROJECTS,
    LOAD_USER_ACCOUNTS,
    REFRESH_STATE,
    SWITCH_DEFAULT_SUBSCRIPTION,
    SWITCH_SUBSCRIPTION,
    UPDATE_USER_MODAL_OPEN,
} from '../../../types/actionTypes';
import { userShim } from '../shim';
import { AccountPreference, JsonUser } from '../types';

const loadCurrentUser = () => (dispatch: Dispatch, _getState: Function) => {
    dispatch({ type: typePending(LOAD_CURRENT_USER) });
    return userShim.loadCurrentUser().subscribe({
        next: (user: JsonUser) => {
            const { subscriptions, selectedSubscriptionId } = user;
            const apiRegion = subscriptions?.find(
                (sub) => sub.subscriptionId === selectedSubscriptionId,
            )?.apiRegion;
            if (apiRegion) {
                store.set(API_REGION, apiRegion);
            }
            dispatch({
                type: typeComplete(LOAD_CURRENT_USER),
                payload: user,
            });
        },
        error: (error: any) => {
            dispatch({ type: typeFail(LOAD_CURRENT_USER), payload: error });
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: { type: NotificationType.ERROR, message: error },
            });
        },
    });
};

const switchSubscription = (subscriptionId: string) => (dispatch: Dispatch, getState: Function) => {
    dispatch({ type: typePending(SWITCH_SUBSCRIPTION) });
    return userShim.switchSubscription(subscriptionId).subscribe({
        next: () => {
            const allState = getState() as UserPortalState;
            const apiRegion = allState.user.subscriptions.find(
                (sub) => sub.subscriptionId === subscriptionId,
            )?.apiRegion;
            if (apiRegion) {
                store.set(API_REGION, apiRegion);
            }
            dispatch({ type: typeComplete(SWITCH_SUBSCRIPTION), payload: subscriptionId });
            dispatch({ type: REFRESH_STATE });
            loadSubscription()(dispatch);
            loadAccountPreferences()(dispatch, getState);
        },
        error: (err: any) => {
            dispatch({ type: typeFail(SWITCH_SUBSCRIPTION), payload: err });
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: { type: NotificationType.ERROR, message: err },
            });
        },
    });
};

const loadAccountPreferences = () => (dispatch: Dispatch, _getState: Function) => {
    dispatch({ type: typePending(LOAD_ACCOUNT_PREFERENCES) });
    return userShim.loadAccountPreferences().subscribe({
        next: (prefs: AccountPreference) => {
            dispatch({
                type: typeComplete(LOAD_ACCOUNT_PREFERENCES),
                payload: prefs,
            });
        },
        error: (error: any) => {
            dispatch({ type: typeFail(LOAD_ACCOUNT_PREFERENCES), payload: error });
        },
    });
};

const loadRecentProjects = () => (dispatch: Dispatch) => {
    dispatch({ type: typePending(LOAD_RECENT_PROJECTS) });
    return userShim.loadRecentProjects().subscribe({
        next: (response: any) => {
            dispatch({ type: typeComplete(LOAD_RECENT_PROJECTS), payload: response });
        },
        error: (err: any) => {
            dispatch({ type: typeFail(LOAD_RECENT_PROJECTS), payload: err });
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: { type: NotificationType.ERROR, message: err },
            });
        },
    });
};

const updateUserModalOpen = (isOpen: boolean) => (dispatch: Dispatch) => {
    dispatch({ type: UPDATE_USER_MODAL_OPEN, payload: isOpen });
};

const updateDefaultSubscription =
    (subscriptionId: string) => (dispatch: Dispatch, _getState: Function) => {
        dispatch({ type: typePending(SWITCH_DEFAULT_SUBSCRIPTION) });
        return userShim.switchDefaultSubscription(subscriptionId).subscribe({
            next: () => {
                dispatch({
                    type: typeComplete(SWITCH_DEFAULT_SUBSCRIPTION),
                    payload: subscriptionId,
                });
            },
            error: (err: any) => {
                dispatch({ type: typeFail(SWITCH_DEFAULT_SUBSCRIPTION), payload: err });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: err },
                });
            },
        });
    };

const loadUserAccounts = () => (dispatch: Dispatch, _getState: Function) => {
    dispatch({ type: typePending(LOAD_USER_ACCOUNTS) });
    return userShim.loadUserAccounts().subscribe({
        next: (prefs: AccountPreference) => {
            dispatch({
                type: typeComplete(LOAD_USER_ACCOUNTS),
                payload: prefs,
            });
        },
        error: (error: any) => {
            dispatch({ type: typeFail(LOAD_USER_ACCOUNTS), payload: error });
        },
    });
};

export const actions = {
    loadCurrentUser,
    loadRecentProjects,
    updateUserModalOpen,
    switchSubscription,
    loadAccountPreferences,
    updateDefaultSubscription,
    loadUserAccounts,
};
